<template>
    <div class="faq-wrapper">
        <div class="page-header page-header-small clear-filter" filter-color="primary">
            <div class="page-header-image"></div>
            <div class="content-center">
                <div class="row">
                    <div class="col-md-10 ml-auto mr-auto">
                        <h1 class="title">Have some questions?</h1>
                        <h4>We know that new things are always scary and that there are lots of questions. We really hope that the below helps, if not please do not hesitate to contact us.</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="group" v-for="questionGroup in questionDataModel" :key="questionGroup.id">
                                <h2>{{ questionGroup.group }}</h2>
                                <collapse>
                                    <collapse-item class="col-md-12" v-for="question in questionGroup.questions" :key="question.id" :title="question.title" :name="question.id">
                                        <div>{{ question.answer }}</div>
                                    </collapse-item>
                                </collapse>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="flex-column align-self-center">
                                <card class="booknow-card text-center">
                                    <h4 class="card-title"><b>Ready to make your booking?</b></h4>
                                    <div class="card-subtitle">Your time and wellness is whats important to us</div>
                                    <n-button type="primary" @click="signup">BOOK NOW</n-button>
                                </card>
                            </div>
                        </div>
                    </div>

                    <div class="separator-line separator-primary"></div>

                    <div class="section section-basic-components">
                        <div class="d-flex flex-row justify-content-center">
                            <div class="flex-column align-self-center">
                                <card class="contactus-card text-center">
                                    <h4 class="card-title"><b>Want to know more?</b></h4>
                                    <p class="card-subtitle">If you would like to get more information, please do not hesitate to contact us <i class="far fa-smile"></i></p>
                                    <n-button type="primary" @click="goToContactUs">CONTACT US</n-button>
                                </card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Card } from '@/components';
    import { Collapse } from '@/components';
    import { CollapseItem } from '@/components';
    import { Button } from '@/components';

    export default {
        components: {
            Card,
            Collapse,
            CollapseItem,
            [Button.name]: Button
        },
        data() {
            return {
                questionDataModel: [
                    {
                        id: "group_1",
                        group: "Why is DrRemo so awesome?",
                        questions: [
                            {
                                id: 1,
                                title: "What is DrRemo?",
                                answer: "DrRemo is an online platform that facilitates interactions between wellness practitioners and patients."
                            },
                            {
                                id: 2,
                                title: "Does DrRemo provide access to prescriptions?",
                                answer: "Unfortunately not. Prescriptions can be discussed and managed directly with your practitioner."
                            },
                            {
                                id: 3,
                                title: "What can I do if I don't find a practitioner?",
                                answer: "We strive to have as many practitioners as possible join our cause, new practitioners sign up all the time so be sure to check again soon."
                            }
                        ]
                    },
                    {
                        id: "group_2",
                        group: "How does the bookings work?",
                        questions: [
                            {
                                id: 4,
                                title: "Does DrRemo provide medical and wellness services?",
                                answer: "No. DrRemo facilitates access to wellness services by connecting practitioners with patients, but does not provide any wellness services itself."
                            },
                            {
                                id: 5,
                                title: "How do I pay for my appointment?",
                                answer: "Upon booking an appointment you will be directed to the payment process where you can pay using a credit or debit card."
                            },
                            {
                                id: 6,
                                title: "Does DrRemo claim from my medical aid?",
                                answer: "No. DrRemo does not integrate with any medical aid schemes. Cash payment is required upon booking, you may however claim from your medical aid yourself."
                            },
                            {
                                id: 7,
                                title: "Can I cancel or postpone my booking?",
                                answer: "Yes. With sufficient lead time you are welcome to cancel or postpone your booking and we will refund accordingly. Please refer to our terms and conditions for more details."
                            },
                            {
                                id: 8,
                                title: "Where can I get a copy of my invoice for a booking?",
                                answer: "Invoices for past bookings are available in PDF format under you profile page."
                            }
                        ]
                    }
                ]
            };
        },

        methods: {
            goToContactUs() {                
                var self = this;
                self.$router.push({name:"contactus"}); 
            },
            signup() {
                window.location.href = process.env.VUE_APP_SIGNEDINENDPOINT;;
            }
        },

        metaInfo() {
            return {
                title: "DrRemo | Frequently Asked Questions",
                meta: [
                    {
                        name: "description",
                        content: "A convenient point of reference to information based on frequently asked questions."
                    },
                    {
                        name: "robots",
                        content: "index, follow"
                    }
                ],
                link: [
                    {
                    rel: "canonical",
                    href: "https://dr-remo.co.za/faq"
                    }
                ]
            }
        }
    }
</script>
